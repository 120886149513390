module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e940081e2e24bdd300f2aafb3a1fc606"},
    },{
      plugin: require('../node_modules/@raae/gatsby-plugin-let-it-snow/gatsby-browser.js'),
      options: {"plugins":[],"duration":60,"intensity":"regular","season":{"start":"2001-09-25T00:00:00.000Z","end":"2001-01-04T00:00:00.000Z"},"colors":["#fff"]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
